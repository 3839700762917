import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import { Parallax } from 'react-scroll-parallax';
const foto = () => (
  <Layout>
    <SEO title="Fotókidolgozás" description="Egyénre szabott optika Zugló szívében"  />
    <div className="sitewrap xl:max-w-2xl justify-center  mr-auto ml-auto      mt-10 ">
    <div className="  flex    mr-auto ml-auto   mt-20">
    <div className="F12F7B p-4      mb-10 mt-10 ">
    



<div className="left2 mt-20 "><h3 className="maintext2 left2 ">2 munkanap alatt készül el fotóinak nagyítása, különböző, akár poszter méretben is.</h3>













</div></div>

    </div></div>
  </Layout>
)

export default foto
